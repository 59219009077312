@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;800&display=swap');

.ambler-container {
  font-family: Arial, sans-serif;
  padding-left: 20px;
  padding-right: 20px;
  background: #F7EBFE;
  min-height: 100vh;
}

.brand {
  color: #000;
  font-family: Kanit;
  font-size: 46.8px;
  font-style: normal;
  font-weight: 500;
  line-height: 131.023%;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}

.auth-buttons button {
  margin-left: 10px;
}

main {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chat {
  background-color: #3A3A3A;
  padding: 10px;
  margin-top: 30px;
  border-radius: 5px;
  width: calc(100% - 40px);
  max-width: 600px;

}

.chat-msg {
  margin-bottom: 20px;
}

.me {
  color: #555;
  text-align: right;
}

.other-person {
  color: #222;
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.actions button {
  margin-right: 10px;
  padding: 10px 20px;
  border-radius: 100px;
  border: 0px;
  color: #FFF;

  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;

  width: 200px;
}

.actions .download-button {
  background: #3A3A3A;
}

.actions .summarize-button {
  background: #5F44CD;
}

.hero {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

h2 {
  color: #5F44CD;
  font-size: 46.8px;
  font-style: normal;
  font-weight: 800;
  line-height: 131.023%;
  margin: 0;
}

h3 {
  color: #000;
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 300;
  line-height: 131.023%;
  margin: 0;
}

.chat-msg-info {
  color: #D9D9D9;
  font-family: Courier New;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.023%;
}

.chat-msg-content {
  color: #FFF;
  font-family: Courier;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 131.023%;
}

.record-button {
  background: #5F44CD;
  padding: 10px 20px;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  border: 0;
  border-radius: 100px;
  margin-top: 30px;
  cursor: pointer;
}

.login-button {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 10px 20px;
}

.signup-button {
  cursor: pointer;
  padding: 10px 20px;
  background: #5F44CD;
  color: #FFF;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  border-radius: 100px;
  cursor: pointer;
  border: 0;
}